<template>
  <div class="container">
    <div class="content bg-gray">
      <div class="card" :class="{ 'is-end': useEnd }">
        <div v-if="useEnd" class="card-end">
          <p class="card-end-text">
            ご利用期間は終了しました<br />
            （2021年4月30日で終了しました）
          </p>
        </div>
        <div class="card-set">
          <div class="card-content">
            <img class="card-bg" src="https://source.unsplash.com/random" alt="" />
            <div v-if="isLogin" class="card-top">
              <p class="card-name">杉浦 太一様</p>
            </div>
            <div v-if="!isLogin || useEnd" class="card-login">
              <router-link class="card-login-btn" :to="{name:'Login', params:{directory:$route.params.directory}}">
                <p class="card-login-text">
                  <span class="card-login-strong">ログイン・会員登録</span><br />
                  はこちら
                </p>
                <i class="icon icon-chevron-gray card-login-icon"></i>
              </router-link>
            </div>
            <div class="card-bottom">
              <div v-if="isLogin" class="card-balance">
                <p class="card-balance-headline">残高</p>
                <p class="card-balance-num">{{ 16300 | comma }}<span class="card-balance-unit">円</span></p>
              </div>
              <div class="card-limit">
                <p class="card-limit-headline">有効期限</p>
                <p class="card-limit-day">2021年4月30日</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="section section-bd inner-H inner-V pt0">
        <section class="home-action">
          <h2 class="headline ta-l">〇〇市プレミアム付商品券</h2>
          <ul v-if="isLogin" class="home-action-set">
            <li class="home-action-item">
              <router-link class="home-action-btn" :to="{name:'HistoryAll', params:{directory:$route.params.directory}}"><i class="icon icon-history home-action-icon"></i>ご利用履歴</router-link>
            </li>
            <li class="home-action-item">
              <router-link class="home-action-btn" :to="{name:'Mypage', params:{directory:$route.params.directory}}"><i class="icon icon-mypage home-action-icon"></i>マイページ</router-link>
            </li>
          </ul>
          <router-link class="btn btn-main" :to="{name:'Search', params:{directory:$route.params.directory}}">使えるお店を探す</router-link>
        </section>
        <section v-if="isLogin" class="home-activity">
          <h2 class="home-activity-headline">最近の取引</h2>
          <div class="home-activity-set">
            <p class="home-activity-time">10分前</p>
            <p class="home-activity-title"><i class="icon icon-yen home-activity-icon"></i>チャージ（カード）</p>
            <div class="home-activity-content">
              <p class="home-activity-detail">VISA からチャージしました</p>
              <p class="home-activity-num">{{ 3000 | comma }}<span class="home-activity-unit">円</span></p>
            </div>
          </div>
        </section>
      </section>
      <section class="section inner-H inner-V">
        <h2 class="headline ta-l">〇〇市プレミアム付商品券とは</h2>
        <p class="mb25">市民の消費を喚起し、新型コロナウイルス感染症拡大に伴い、影響を受けた地域経済の回復に資すること、また、様々な不安を抱えながら子育てをしている子育て世帯の親や子の生活支援と経済的な負担を軽減することを目的としています。</p>
        <router-link class="btn btn-w160 btn-sm btn-bd-white" to="">詳しく見る<i class="icon icon-chevron-gray btn-icon"></i></router-link>
      </section>
    </div>
    <Footer :class="{ 'is-actTab': isLogin }" />
    <div v-if="isLogin" class="actTab">
      <ul class="actTab-set">
        <li class="actTab-item" v-if="!payOnly" :class="{ 'is-end': buyEnd }">
          <button class="actTab-btn" type="button" :disabled="buyEnd" @click="goCharge"><i class="icon icon-charge-circle actTab-icon"></i><span class="actTab-text">チャージする</span></button>
        </li>
        <li class="actTab-item">
          <button class="actTab-btn" type="button"><i class="icon icon-pay-circle actTab-icon"></i><span class="actTab-text">支払う</span></button>
        </li>
      </ul>
    </div>
    <button v-if="isLogin" class="monitoring-notice" type="button" @click="monitoringOpen">
      <i class="icon icon-monitoring-white monitoring-notice-icon"></i>
      <div class="monitoring-notice-content">
        <p class="monitoring-notice-title">みまもり設定</p>
        <p class="monitoring-notice-text">承認依頼が届きました</p>
      </div>
      <i class="icon icon-chevron-white monitoring-notice-arrow"></i>
    </button>
    <Modal @close="monitoringClose" v-if="monitoringShow">
      <template v-slot:headline>
        <i class="icon icon-monitoring modal-header-icon"></i>
        みまもり設定の承認依頼
      </template>
      <template v-slot:body>
        <p class="ta-c">
          羽田 創希様からの<br />
          みまもり設定を承認しますか？
        </p>
      </template>
      <template v-slot:footer>
        <ul class="btnRow">
          <li class="btnRow-item">
            <button class="btn btn-rd btn-sm btn-bd-gray" type="button" @click="monitoringClose">いいえ</button>
          </li>
          <li class="btnRow-item">
            <button class="btn btn-rd btn-sm btn-main" type="button" @click="monitoringClose">はい</button>
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
//modal
import Modal from '@/components/Modal.vue';
import modal from '@/mixins/modal';

export default {
  name: 'Home',
  data: function() {
    return {
      //headerData
      pageName: 'logo.svg',
      pageBack: false,
      pageLogo: true,
      //home
      loginStatus: this.$parent.isLogin,
      monitoringShow: false,
      //limit
      useEnd: false,
      buyEnd: false,
      //stop
      isStop: false,
      //payOnly
      payOnly: true,
    };
  },
  props: {
    isLogin: {
      required: false,
    },
  },
  components: {
    Modal,
  },
  mixins: [modal],
  methods: {
    goCharge: function() {
      this.$router.push({ name: 'Charge' });
    },
    monitoringOpen() {
      this.monitoringShow = true;
    },
    monitoringClose() {
      this.monitoringShow = false;
    },
  },
};
</script>
