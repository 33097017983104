export default {
  data: function() {
    return {
      modal: false,
    };
  },
  methods: {
    modalShow() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
  },
};
